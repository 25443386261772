/** Theme */
:root {
    --color-primary: #00afd2;
    --color-secondary: #444544;
    --color-success: #00cb11;
    --color-orange: #ffaa00;
    --color-danger: #ff4444;
    --color-dark: #030633;
    --color-info: #4dace4;
    --color-darkgrey: #333;
    --color-lightgrey: #f6f8fa;
    --color-nearlightgrey: #e9edf2;
    --color-grey: #9b9ba2;
    --color-grad: linear-gradient(to right, var(--color-primary), var(--color-light));
}
body {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 300;
}
h1,h2,h3,h4,h5,h6 {
    font-weight: 600;
}

/** Link */
a {
    color: var(--color-primary);
    text-decoration: none;
}
span.link {
    color: var(--color-primary);
}
span.link:hover {
    color: blue;
    cursor: pointer;
}
a:hover {
    text-decoration: unset;
}

/** HR */
hr.color-border {
    border-color: var(--bs-border-color);
    opacity: 1;
}

/** Color */
.color-primary {
    color: var(--color-primary);
}
.color-secondary {
    color: var(--color-secondary);
}
.color-lightgrey {
    color: var(--color-lightgrey);
}

/** Page Title */
.page-title {
    font-weight: 700;
    text-transform: uppercase;
    color: var(--color-darkgrey)
}
.page-title.admin {
    color: #000;
    margin-bottom: 1rem;
}
.page-title .action {
    color: inherit !important;
    opacity: 0.5;
    cursor: pointer;
}
.page-title .action:hover {
    opacity: 1 !important;
    color: inherit !important;
}

.action {
    color: inherit !important;
    opacity: 0.5;
    cursor: pointer;
}

.action:hover {
    opacity: 1 !important;
    color: inherit !important;
}

/** Navigation Bar */
nav.navbar {
    display: flex;
    transition: all 0.3s
}
nav.navbar.bg-white {
    background-color: rgba(255,255,255,0.8) !important;
}
.navbar-nav {
    flex-direction: row-reverse;
}
.navbar-dark .navbar-nav .nav-link {
    color: #fff;
}
.userphoto-placeholder,
.navbar .userphoto {
    height:30px;
    width:30px;
    background-color: var(--color-primary);
    border-radius: 50%;
    display: block;
    color:#fff
}
.userphoto-placeholder.small {
    height:22px;
    width:22px;
    font-size: 0.6rem;
}
.userphoto-placeholder.modal-active {
    height:75px;
    width:75px;
}

.icon-round-placeholder {
    height:30px;
    width:30px;
    border-radius: 50%;
    display: block;
    color:#fff
}

/** Background */
.bg-primary {
    background-color: var(--color-primary) !important;
}
.bg-secondary {
    background-color: var(--color-secondary) !important;
}
.bg-orange {
    background-color: var(--color-orange) !important;
}
.bg-info {
    background-color: var(--color-info) !important;
}
.bg-lightgrey {
    background-color: var(--color-lightgrey) !important;
}
.bg-grey {
    background-color: var(--color-grey) !important;
}
.bg-darkgrey {
    background-color: var(--color-darkgrey) !important;
}
.bg-success {
    background-color: var(--color-success) !important;
}
.bg-danger {
    background-color: var(--color-danger) !important;
}

/* Border */
.border-info {
    border: 1px solid var(--color-info) !important;
}
.border-pink {
    border: 1px solid #e5a2a2 !important;
}

/** Headings */
.heading-1 {
    padding: 8px 10px;
    background-color: #eeeef6;
    border-radius: 5px;
    font-weight: 600;
    margin-bottom: 15px;
}

/** Button */
.btn.btn-primary {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}
.btn.btn-darkgrey {
    background-color: var(--color-darkgrey);
    border-color: var(--color-darkgrey);
    color: #fff;
}
.btn.btn-outline-primary:active,
.btn.btn-outline-primary.active,
.btn.btn-outline-primary {
    background-color: transparent;
    border-color: var(--color-primary);
    color: var(--color-primary)
}
.btn.btn-outline-secondary {
    background-color: transparent;
    border-color: var(--color-secondary);
    color: var(--color-primary)
}
.btn.btn-outline-grey {
    background-color: transparent;
    border-color: var(--color-grey);
    color: var(--color-grey)
}
.btn.btn-outline-light {
    background-color: #ffffff;
    border-color: #d2d2d6;
    color:#000000;
}
.btn.btn-secondary {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
}
.btn.btn-outline-secondary {
    background-color: transparent;
    border-color: var(--color-secondary);
    color: var(--color-secondary)
}
.btn.btn-success {
    background-color: var(--color-success);
    border-color: var(--color-success);
}
.btn.active {
    background-color: #2664af!important;
    border-color: #2664af!important;
}

/** Card */
.card {
    border: 0px;
    border-radius: 8px;
    /* box-shadow: 2px 3px 2px rgb(227 231 235); */
    box-shadow: 0.5px 2px 1px 0.5px rgb(227 231 235);
}

/** Pagination */
.pagination {
    margin-bottom: 0px;
}

/** Modal */
.modal-dialog.modal-70w {
    max-width: 70%;
}
.modal-backdrop.level-2 {
    z-index: 1060;
}
.modal-backdrop.level-2 + .modal {
    z-index: 1065;
}
.modal-dialog {
    margin: 0.5rem auto;
}
.companies-modal .other-companies .delete-action {
    opacity: 0;
    z-index: 666;
}
.companies-modal .other-company:hover .delete-action {
    opacity: 0.3;
}

/** Pagination */
.page-item.active .page-link {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}
.page-link {
    color: var(--color-darkblue)
}

/** Dropdown */
.dropdown-item a {
    color: var(--color-darkgrey) !important
}
.dropdown-item:active {
    background-color: var(--color-lightgrey);
}

/** File */
.file-wrapper.not-image-file  {
    max-width: 300px !important;
    overflow-wrap: anywhere;
}
.file-wrapper {
    height: 150px !important;
    min-width: 150px !important;
    position: relative;
    border: 1px solid #ddd;
    border-radius: 5px;
}
.file-wrapper.image-file {
    text-align: center;
}
.file-wrapper.image-file > img {
    border-radius: 5px;
}
.file-btn {
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 10px;
    text-shadow: 2px 2px 5px rgba(0,0,0,0.3);
}
.existing-doc:hover .delete {
    display: inline !important;
}

/** Show/Hide Columns Modal */
.columns-selection.selected {
    background-color: var(--color-lightgrey);
    color: var(--color-darkblue);
}
.showhide-columns-modal .shown-column-actions {
    visibility: hidden;
}
.showhide-columns-modal .shown-column:hover {
    background: var(--color-lightgrey);
}
.showhide-columns-modal .shown-column:hover .shown-column-actions {
    visibility: visible;
}

/** Select Required*/
.select-required {
    border-color: rgb(245, 241, 241, 0) !important;
    position:absolute;
    height:100%;
    width:100%;
    left:0px;
    top:0px;
}

/** Bootstrap Tabs */
.nav-tabs .nav-link {
    background-color: var(--color-lightgrey);
    margin-right: 5px;
    color: #000;
}

/** React Datepicker */
input.react-datepicker-time__input {
    border-radius: 5px;
    padding: 2px 5px;
    border: 1px solid #ccc;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
    display: none;
}

/** React Select */
.input-group-react-select > div {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
}
.navbar .css-hd1jre-placeholder {
    text-wrap: nowrap;
}

/** Status Selector */
.status-selector-line {
    height: 5px;
    width: 90%;
    top: 18px;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.status-selector .status-selector-circle {
    border: 2px solid var(--color-primary);
    background-color: #fff;
    width: 40px; 
    height: 40px;
}
.status-selector.is-active .status-selector-circle {
    border: 2px solid var(--color-primary);
    background-color: var(--color-primary);
    color: #fff;
}

/** Show/Hide Columns Modal */
.columns-selection.selected {
    background-color: var(--color-lightgrey);
    color: var(--color-darkblue);
}
.showhide-columns-modal .shown-column-actions {
    visibility: hidden;
}
.showhide-columns-modal .shown-column:hover {
    background: var(--color-lightgrey);
}
.showhide-columns-modal .shown-column:hover .shown-column-actions {
    visibility: visible;
}

/** Table */
table i.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.3;
}
table tr.row-bold * {
    font-weight: bold;
}

/** Pages */
.bank-cash-flow .bank {
    transition: all 0.5s;
    cursor: pointer;
}
.bank-cash-flow .bank:hover {
    box-shadow: 0px 5px 15px rgba(0,0,0,0.05);
}

/** CSS Transitions **/
.fadein-item {
    -webkit-animation: fadein .5s linear forwards;
    animation: fadein .5s linear forwards;
    padding: 10px;
}

@-webkit-keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
    
@keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

/** Print */
@media all {
    .page-break {
        display: none;
      }
  }
  
  @media print {
    html,
    body {
      height: initial!important;
      overflow: initial!important; 
      --webkit-print-color-adjust: exact;
    }
  }
  
  @media print {
    .page-break {
        margin-top: 1 rem;
        display: block;
        page-break-before: auto;
      }
  }
  
  @page {
    size: auto;
    margin: 20 mm;
  }