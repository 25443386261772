/* // X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

    /** Cash Flow Filter */
    .cash-flow-filter .actions-container button {
        font-size: 0.7rem;
        padding: 5px
    }
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {

    /** Sidebar **/
    .sidebar-bottom,
    nav.sidebar {
        max-width: 200px;
        z-index: 9999;
        transition: all .3s ease-in-out;
        left: -300px;
    }

    .sidebar-bottom.expanded,
    nav.sidebar.expanded {
        left: 0px;
    }
    .sidebar-bottom.expanded {
        width: 100% !important;
    }

    /** Navbar */
    nav.navbar {
        justify-content: space-between !important;
    }

    /** Table */
    table.table {
        font-size: .75rem;
    }

    /** Modal */
    .modal-dialog {
        max-width: unset !important;
    }


}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    .home-top-card .border-end {
        border-right: none !important;
    }

    .status-selector-line {
        top: 11px !important;
    }
    
    .status-selector .status-selector-circle {
        width: 30px !important;
        height: 30px !important;
        font-size: .6rem;
    }
}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {}

/* // X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {}